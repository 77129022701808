<template>
  <div class="mt-1 mx-3">
    <agency-treasury-create></agency-treasury-create>
  </div>
</template>

<script>
import AgencyTreasuryCreate from "@/components/profile/treasuries/agency/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    AgencyTreasuryCreate,
  },
  created() {
    this.notifyQueue({
      text: "Forme di pagamento di Agenzia",
      path: "module.PROF.CAGG",
      tab: "AgencyTreasuries",
      level: 3,
    });
    this.notifyQueue({
      text: "Crea Forme di pagamento di Agenzia",
      path: "treasuries.create0",
      level: 4,
    });
  },
};
</script>

<template>
  <div class="mt-1 mx-3">
    <store-treasury></store-treasury>
  </div>
</template>

<script>
import StoreTreasury from "@/components/profile/treasuries-cash-references/treasury/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    StoreTreasury,
  },
  created() {
    this.notifyQueue({
      text: "Crea Conto",
      path: "treasuries.create2",
      level: 4,
    });
  },
};
</script>

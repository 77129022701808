<template>
  <div class="mt-1 mx-3">
    <agency-treasuries> </agency-treasuries>
  </div>
</template>

<script>
import AgencyTreasuries from "@/components/profile/treasuries/agency/index.vue";
import { mapActions } from 'vuex';

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    AgencyTreasuries,
  },
  created() {
    this.notifyQueue({ text: "Forme di pagamento di Agenzia", path: "treasuries.index0", level: 1 });
  },
};
</script>
